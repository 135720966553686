import _ from "lodash"

import MapAPI from "@/api/MapAPI"
import { CROP_ID_TO_ICON_URL, DROPDOWN, MAP_LAYER, IS_RANCHFORCE } from "@/constants"
import store from "@/store"
import { Filter, Map } from "@/store/modules"
import { findMinMax } from "@/utility"

import {
  AGTGlobal,
  //AGTByCrop,
  AGTByElevation,
  BoundaryLayer,
  Elevation,
  Fertilizer,
  Harvest,
  Planting,
  Profit,
  ProfitByAgt,
  SelectedLayer,
  YieldByElevation,
  CropIconLayer,
  MicroclimateLayer,
} from "@/components/map/layers"

import {
  AGTGlobalJSON,
  //AGTByCrop,
  AGTByElevationJSON,
  ElevationJSON,
  FertilizerJSON,
  HarvestJSON,
  PlantingJSON,
  ProfitJSON,
  ProfitByAgtJSON,
  YieldByElevationJSON,
} from "@/components/map/layersGeojson"

const ARVA_LAYERS = [
  MAP_LAYER.YieldByElevation,
  // MAP_LAYER.ProfitByAGT,
  // MAP_LAYER.AGTByElevation,
  MAP_LAYER.Profit,
  MAP_LAYER.Microclimate,
]
const DATA_LAYERS = [
  MAP_LAYER.Boundary,
  MAP_LAYER.FieldBorder,
  MAP_LAYER.Elevation,
  MAP_LAYER.Planting,
  MAP_LAYER.Fertilizer,
  MAP_LAYER.Harvest,
]

const defaultOpacities = () => {
  const opacities = {}
  ARVA_LAYERS.concat(DATA_LAYERS)
    .concat([MAP_LAYER.AGT])
    .forEach(layerId => {
      opacities[layerId] = 1
      if (layerId === MAP_LAYER.AGT) opacities[layerId] = 0.6
      if (layerId === MAP_LAYER.Microclimate) opacities[layerId] = 0.5
    })
  return opacities
}

const getDefaultState = () => {
  return {
    deck: {},
    isFetching: false,
    fieldData: {},
    voxelBounds: {},
    microClimates: {},
    layers: {},
    activeLayerIds: [MAP_LAYER.Boundary],
    activeCrops: [],
    opacities: defaultOpacities(),
    elevationScale: {
      [MAP_LAYER.Elevation]: 15,
      [MAP_LAYER.YieldByElevation]: 15,
      [MAP_LAYER.AGTByElevation]: 15,
      [MAP_LAYER.Profit]: 15,
    },
    hoverData: null,
    rightClickData: null,
    cropIconOpacity: 100,
    includeDefaults: true,
    includeAnimalCounts: true,
    showCropIcons: false,
    zoom: null,
    agtCrops: [],
    pitchMode: false,
    currentPitch: 0,
    currentBearing: 0,
    extentBounds: {},
    singleFieldExtentBounds: {},
    orgAgtCrops: [],
    // begin seasonality - allow users to view specific crops/ferts for fert, planting, and harvest
    showFert: "All",
    showNewFertOptions: false,
    activeFerts: ["All"],
    activeFertsDefaults: ["All"],
    showSeed: "All",
    showNewSeedOptions: false,
    activeSeeds: ["All"],
    activeSeedsDefaults: ["All"],
    showHarvestCrop: "All",

    showNewHarvestOptions: false,
    activeHarvests: ["All"],
    activeHarvestsDefaults: ["All"],
    fieldBoundaries: [],
    showMicroclimate: false,
    microclimateOpacity: 50,
    mvtYear: null,
    organizationId: null,
    mvtURLs: null,
  }
}

const state = getDefaultState()

const extractFieldIdsFromStore = state => {
  const selectedFields = state.Filter.selectedFields
  const fieldIds = Object.keys(selectedFields).filter(
    fieldId => selectedFields[fieldId]
  )
  return fieldIds.map(fieldId => parseInt(fieldId))
}

const scaleOpacity = opacity => Math.pow(opacity, 2)
const scaleElevationScale = elevationScale => elevationScale / 40

const getters = {
  [Map.Getters.getArvaLayers]: state =>
    Object.entries(state.layers)
      .filter(layerId => ARVA_LAYERS.includes(parseInt(layerId[0])))
      .map(layer => layer[1]),

  [Map.Getters.getDataLayers]: state =>
    Object.entries(state.layers)
      .filter(layerId => DATA_LAYERS.includes(parseInt(layerId[0])))
      .map(layer => layer[1]),

  [Map.Getters.getIncludeDefaults]: state => state.includeDefaults,
  [Map.Getters.getIncludeAnimalCounts]: state => state.includeAnimalCounts,
  [Map.Getters.getShowMicroclimate]: state => state.showMicroclimate,
  [Map.Getters.getMicroclimateOpacity]: state => state.microclimateOpacity,

  [Map.Getters.getAgtCrops]: state => state.agtCrops,

  [Map.Getters.getOpacities]: state => state.opacities,

  [Map.Getters.getElevationScale]: state => state.elevationScale,

  [Map.Getters.getShowCropIcons]: state => state.showCropIcons,

  [Map.Getters.getCropIconOpacity]: state => state.cropIconOpacity,

  // begin seasonality
  [Map.Getters.getActiveFerts]: state =>
    state.includeDefaults ? state.activeFertsDefaults : state.activeFerts,

  [Map.Getters.getShowFert]: state => state.showFert,

  [Map.Getters.getShowNewFertOptions]: state => state.showNewFertOptions,

  [Map.Getters.getActiveSeeds]: state =>
    state.includeDefaults ? state.activeSeedsDefaults : state.activeSeeds,

  [Map.Getters.getShowSeed]: state => state.showSeed,

  [Map.Getters.getShowNewSeedOptions]: state => state.showNewSeedOptions,

  [Map.Getters.getActiveHarvests]: state =>
    state.includeDefaults ? state.activeHarvestsDefaults : state.activeHarvests,

  [Map.Getters.getShowHarvest]: state => state.showHarvestCrop,

  [Map.Getters.getShowNewHarvestOptions]: state => state.showNewHarvestOptions,
}

const calculateExtentBounds = (fieldBoundData, activeFieldIds) => {
  if (!fieldBoundData) return {}
  const latLongValues = fieldBoundData.features
    .filter(feat => activeFieldIds.includes(feat.properties.field.id))
    .map(feat => feat.geometry.coordinates[0][0])

  const flatValues = [].concat(...latLongValues)
  const longVals = new Float64Array(flatValues.length)
  const latVals = new Float64Array(flatValues.length)

  for (let i = 0; i < flatValues.length; i++) {
    longVals[i] = flatValues[i][0]
    latVals[i] = flatValues[i][1]
  }

  var minMaxLat = findMinMax(latVals)
  const minLatitude = minMaxLat[0]
  const maxLatitude = minMaxLat[1]

  var minMaxLong = findMinMax(longVals)
  const minLongitude = minMaxLong[0]
  const maxLongitude = minMaxLong[1]

  return { minLatitude, maxLatitude, minLongitude, maxLongitude }
}

const getVoxelFeatures = (
  fieldData,
  agtCrops,
  activeFieldIds,
  activeLayerIds,
  includeDefaults,
  selectedFert = "All",
  selectedSeed = "All",
  selectedHarv = "All"
) => {
  let activeHarvests = new Set(["All"])
  let activeSeeds = new Set(["All"])
  let activeFerts = new Set(["All"])

  const agtFeatures = []
  const elevationFeatures = []
  const yieldFeatures = []
  const yieldByElevationFeatures = []
  const plantingFeatures = []
  const fertFeatures = []
  const profitFeatures = []
  const agtByElevationFeatures = []
  const profitByAGTFeatures = []

  const activeFieldIdsDict = {}
  activeFieldIds.forEach(fieldId => (activeFieldIdsDict[fieldId] = true))

  const showAgt = agtCrops.length > 0
  const showElevation = activeLayerIds.includes(MAP_LAYER.Elevation)
  const showHarvest = activeLayerIds.includes(MAP_LAYER.Harvest)
  const showYieldByElevation = activeLayerIds.includes(
    MAP_LAYER.YieldByElevation
  )
  const showPlanting = activeLayerIds.includes(MAP_LAYER.Planting)
  const showFert = activeLayerIds.includes(MAP_LAYER.Fertilizer)
  const showProfit = activeLayerIds.includes(MAP_LAYER.Profit)
  const showAgtByElevation = activeLayerIds.includes(MAP_LAYER.AGTByElevation)
  const showProfitByAgt = activeLayerIds.includes(MAP_LAYER.ProfitByAGT)
  //const showMicroclimate = activeLayerIds.includes(MAP_LAYER.Microclimate)

  let anyFert = false
  let anyHarvest = false
  let anyPlanting = false

  for (const fieldId in fieldData) {
    if (!activeFieldIdsDict[fieldId]) continue
    const field = fieldData[fieldId]

    for (const voxelId in field.voxelData) {
      const {
        agts,
        cropId,
        elevationMeters,
        geom: geometry,
        fertAmountPerAcre,
        fertAmountPerAcreDefaults,
        plantingSeedsPerAcre,
        plantingSeedsPerAcreDefaults,
        yieldPerAcre,
        yieldPerAcreDefaults,
        // added for seasonality
        fertAmountPerAcreNew,
        fertAmountPerAcreDefaultsNew,
        plantingSeedsPerAcreNew,
        plantingSeedsPerAcreDefaultsNew,
        yieldPerAcreNew,
        yieldPerAcreDefaultsNew,
        roiProfit,
      } = field.voxelData[voxelId]

      const fertNew =
        (fertAmountPerAcreNew !== null && fertAmountPerAcreNew !== undefined) ||
        (fertAmountPerAcreDefaultsNew !== null &&
          fertAmountPerAcreDefaultsNew !== undefined)
      // fertilizer seasonality, 'New' will be removed as voxel caching is updated
      if (fertNew) {
        const fertForVox = includeDefaults
          ? fertAmountPerAcreDefaultsNew
          : fertAmountPerAcreNew
        const hasFert = !_.isEmpty(fertForVox)

        let voxelFerts = []
        if (hasFert) {
          if (!anyFert) {
            fertForVox.forEach(fert => {
              if (!Object.values(fert).every(x => x === null || x === "")) {
                anyFert = true
              }
            })
          }

          voxelFerts = _.flattenDeep([
            ...new Set(fertForVox.map(obj => Object.keys(obj))),
          ])
          voxelFerts.forEach(item => activeFerts.add(item))
        }

        if (showFert && hasFert) {
          const pushFert =
            selectedFert === "All" ? true : voxelFerts.includes(selectedFert)
          if (pushFert) {
            const fertForVoxel =
              selectedFert === "All"
                ? fertForVox[0]
                : fertForVox.find(obj =>
                    Object.keys(obj).includes(selectedFert)
                  )
            fertFeatures.push({
              type: "Feature",
              geometry,
              properties: {
                fertAmountPerAcreDeprecate: Object.values(fertForVoxel)[0],
                fertAmountPerAcreNew: fertAmountPerAcreNew,
                fertAmountPerAcreDefaultsNew: fertAmountPerAcreDefaultsNew,
                fertsInVoxel: voxelFerts.length,
                selectedFert: selectedFert,
                fieldId,
                voxelId,
              },
            })
          }
        }
      } else {
        // this will no longer be needed
        const fertForVox = includeDefaults
          ? fertAmountPerAcreDefaults
          : fertAmountPerAcre
        if (showFert && fertForVox !== undefined) {
          fertFeatures.push({
            type: "Feature",
            geometry,
            properties: {
              fertAmountPerAcreDeprecate: fertForVox,
              fertAmountPerAcreNew: fertAmountPerAcreNew,
              fertAmountPerAcreDefaultsNew: fertAmountPerAcreDefaultsNew,
              fertsInVoxel: null,
              selectedFert: null,
              fieldId,
              voxelId,
            },
          })
        }
      }

      if (showAgt && !_.isEmpty(agts)) {
        const activeAgtCrop = agtCrops.length > 0 ? agtCrops[0] : null
        if (agts[activeAgtCrop]) {
          agtFeatures.push({
            type: "Feature",
            geometry,
            properties: { agt: agts[activeAgtCrop], fieldId, voxelId },
          })
        }
      }

      if (showElevation) {
        elevationFeatures.push({
          type: "Feature",
          geometry,
          properties: {
            elevation: elevationMeters,
            fieldId,
            voxelId,
          },
        })
      }

      if (!cropId) continue

      const yieldNew =
        (yieldPerAcreNew !== null && yieldPerAcreNew !== undefined) ||
        (yieldPerAcreDefaultsNew !== null &&
          yieldPerAcreDefaultsNew !== undefined)
      // harvest seasonality, 'New' will be removed as voxel caching is updated
      if (yieldNew) {
        const yieldForVox = includeDefaults
          ? yieldPerAcreDefaultsNew
          : yieldPerAcreNew
        const hasHarvest = !_.isEmpty(yieldForVox)
        let voxelHarvs = []

        if (hasHarvest) {
          if (!anyHarvest) {
            yieldForVox.forEach(harvest => {
              if (!Object.values(harvest).every(x => x === null || x === "")) {
                anyHarvest = true
              }
            })
          }

          voxelHarvs = _.flattenDeep([
            ...new Set(yieldForVox.map(obj => Object.keys(obj))),
          ])
          voxelHarvs.forEach(item => activeHarvests.add(item))
        }
        if (showHarvest && hasHarvest) {
          const pushHarv =
            selectedHarv === "All" ? true : voxelHarvs.includes(selectedHarv)
          if (pushHarv) {
            const harvForVoxel =
              selectedHarv === "All"
                ? yieldForVox[0]
                : yieldForVox.find(obj => {
                    return Object.keys(obj).includes(selectedHarv)
                  })

            yieldFeatures.push({
              type: "Feature",
              geometry,
              properties: {
                yieldPerAcre: yieldForVox, //Object.values(harvForVoxel)[0],
                cropId: Object.keys(harvForVoxel)[0],
                fieldId,
                voxelId,
              },
            })
          }
        }
      } else {
        const yieldForVox = includeDefaults
          ? yieldPerAcreDefaults
          : yieldPerAcre
        if (showHarvest && yieldForVox !== undefined) {
          yieldFeatures.push({
            type: "Feature",
            geometry,
            properties: {
              yieldPerAcre: yieldForVox,
              cropId,
              fieldId,
              voxelId,
            },
          })
        }
      }

      if (yieldNew) {
        const yieldElevForVox = includeDefaults
          ? yieldPerAcreDefaultsNew
          : yieldPerAcreNew

        if (showYieldByElevation && !_.isEmpty(yieldElevForVox)) {
          const voxelYieldElevs = _.flattenDeep([
            ...new Set(yieldElevForVox.map(obj => Object.keys(obj))),
          ])
          const pushYieldElev =
            selectedHarv === "All"
              ? true
              : voxelYieldElevs.includes(selectedHarv)
          if (pushYieldElev) {
            const yieldElevForVoxel =
              selectedHarv === "All"
                ? yieldElevForVox[0]
                : yieldElevForVox.find(obj => {
                    return Object.keys(obj).includes(selectedHarv)
                  })
            yieldByElevationFeatures.push({
              type: "Feature",
              geometry,
              properties: {
                yieldPerAcre: Object.values(yieldElevForVoxel)[0],
                elevation: elevationMeters,
                cropId: Object.keys(yieldElevForVoxel)[0],
                fieldId,
                voxelId,
              },
            })
          }
        }
      } else {
        const yieldElevForVox = includeDefaults
          ? yieldPerAcreDefaults
          : yieldPerAcre
        if (yieldElevForVox !== null && yieldElevForVox !== undefined) {
          // null check here prevents 'ghost' voxels from causing visual errors
          yieldByElevationFeatures.push({
            type: "Feature",
            geometry,
            properties: {
              yieldPerAcre: yieldElevForVox,
              elevation: elevationMeters,
              cropId,
              fieldId,
              voxelId,
            },
          })
        }
      }

      const plantingNew =
        (plantingSeedsPerAcreNew !== null &&
          plantingSeedsPerAcreNew !== undefined) ||
        (plantingSeedsPerAcreDefaultsNew !== null &&
          plantingSeedsPerAcreDefaultsNew !== undefined)
      if (plantingNew) {
        //activeSeeds.add(String(cropId))

        const plantingForVox = includeDefaults
          ? plantingSeedsPerAcreDefaultsNew
          : plantingSeedsPerAcreNew
        const hasPlanting = !_.isEmpty(plantingForVox)
        let voxelSeeds = []
        if (hasPlanting) {
          if (!anyPlanting) {
            plantingForVox.forEach(planting => {
              if (!Object.values(planting).every(x => x === null || x === "")) {
                anyPlanting = true
              }
            })
          }

          //I think there is chance that could add a seed with a null valu ehere still
          voxelSeeds = _.flattenDeep([
            ...new Set(plantingForVox.map(obj => Object.keys(obj))),
          ])

          voxelSeeds.forEach(item => activeSeeds.add(item))
        }
        if (showPlanting && hasPlanting) {
          const pushSeed =
            selectedSeed === "All" ? true : voxelSeeds.includes(selectedSeed)
          if (pushSeed) {
            const seedForVoxel =
              selectedSeed === "All"
                ? plantingForVox[0]
                : plantingForVox.find(obj =>
                    Object.keys(obj).includes(selectedSeed)
                  )

            plantingFeatures.push({
              type: "Feature",
              geometry,
              properties: {
                plantingSeedsPerAcre: Object.values(seedForVoxel)[0],
                cropId,
                fieldId,
                voxelId,
              },
            })
          }
        }
      } else {
        const plantingForVox = includeDefaults
          ? plantingSeedsPerAcreDefaults
          : plantingSeedsPerAcre
        if (showPlanting && plantingForVox !== undefined) {
          plantingFeatures.push({
            type: "Feature",
            geometry,
            properties: {
              plantingSeedsPerAcre: plantingForVox,
              cropId,
              fieldId,
              voxelId,
            },
          })
        }
      }

      if (showProfit) {
        // check for missing profit to prevent "invisible" voxels with elevation on
        if (roiProfit !== null) {
          profitFeatures.push({
            type: "Feature",
            geometry,
            properties: {
              profit: roiProfit,
              cropId,
              fieldId,
              voxelId,
            },
          })
        }
      }

      const agt = agts[cropId]
      if (showAgtByElevation && !!agt) {
        agtByElevationFeatures.push({
          type: "Feature",
          geometry,
          properties: {
            agt,
            cropId,
            elevation: elevationMeters,
            fieldId,
            voxelId,
          },
        })
      }

      if (showProfitByAgt && !!agt) {
        profitByAGTFeatures.push({
          type: "Feature",
          geometry,
          properties: { agt, cropId, profit: roiProfit, fieldId, voxelId },
        })
      }
    }
  }

  const featureCollection = features => {
    return { type: "FeatureCollection", features }
  }

  state.activeFerts = [...activeFerts]
  state.activeFertsDefaults = [...activeFerts]
  state.activeSeeds = [...activeSeeds]
  state.activeSeedsDefaults = [...activeSeeds]
  state.activeHarvests = [...activeHarvests]
  state.activeHarvestsDefaults = [...activeHarvests]

  if (!state.activeSeeds.includes(String(state.showSeed))) {
    state.showSeed = "All"
  }
  if (!state.activeHarvests.includes(String(state.showHarvestCrop))) {
    state.showHarvestCrop = "All"
  }

  return {
    [MAP_LAYER.AGT]: featureCollection(agtFeatures),
    [MAP_LAYER.Elevation]: featureCollection(elevationFeatures),
    [MAP_LAYER.Harvest]: featureCollection(yieldFeatures),
    [MAP_LAYER.YieldByElevation]: featureCollection(yieldByElevationFeatures),
    [MAP_LAYER.Planting]: featureCollection(plantingFeatures),
    [MAP_LAYER.Fertilizer]: featureCollection(fertFeatures),
    [MAP_LAYER.Profit]: featureCollection(profitFeatures),
    [MAP_LAYER.AGTByElevation]: featureCollection(agtByElevationFeatures),
    [MAP_LAYER.ProfitByAGT]: featureCollection(profitByAGTFeatures),
    [MAP_LAYER.Microclimate]: featureCollection([]),
    anyHarvest: anyHarvest,
    anyPlanting: anyPlanting,
    anyFert: anyFert,
  }
}

const buildLayers = (
  state,
  fieldData,
  activeFieldIds,
  recalcLayerData = false
) => {
  const onHover = evt => {
    const { object, x, y } = evt

    if (object) {
      const { properties } = object

      let agt = null

      if (state.mvtURLs == "None") {
        const { fieldId, voxelId } = properties
        const { agtCrops } = state

        if (!fieldId) {
          state.hoverData = null
          return
        }

        const fieldData = state.fieldData[fieldId] || {}
        const { acreage, farmName, fieldName, voxelData } = fieldData

        const voxel = voxelData[voxelId]
        if (!voxel) {
          state.hoverData = null
          return
        }
        const {
          agts,
          cropId,
          elevationMeters,
          yieldPerAcre,
          yieldPerAcreDefaults,
          plantingSeedsPerAcre,
          plantingSeedsPerAcreDefaults,
          fertAmountPerAcre,
          fertAmountPerAcreDefaults,
          roiProfit,
          seedVariety,
          fertProduct,
          voxelAcres,
          fertAmountPerAcreNew,
          fertAmountPerAcreDefaultsNew,
          plantingSeedsPerAcreNew,
          plantingSeedsPerAcreDefaultsNew,
          yieldPerAcreNew,
          yieldPerAcreDefaultsNew,
        } = voxel

        const agt = agtCrops.length === 0 ? agts[cropId] : agts[agtCrops[0]]

        // TODO remove this logic when MapVoxels fully updated to new format...
        // ...the 'new' values should be removed
        let plantingHover, yieldHover
        const plantingNew =
          plantingSeedsPerAcreNew !== null ||
          plantingSeedsPerAcreDefaultsNew !== null
        const yieldNew =
          yieldPerAcreNew !== null || yieldPerAcreDefaultsNew !== null
        const fertHover = state.includeDefaults
          ? fertAmountPerAcreDefaults
          : fertAmountPerAcre

        if (plantingNew) {
          plantingHover = state.includeDefaults
            ? plantingSeedsPerAcreDefaultsNew
            : plantingSeedsPerAcreNew
        } else {
          plantingHover = state.includeDefaults
            ? plantingSeedsPerAcreDefaults
            : plantingSeedsPerAcre
        }
        if (yieldNew) {
          yieldHover = state.includeDefaults
            ? yieldPerAcreDefaultsNew
            : yieldPerAcreNew
        } else {
          yieldHover = state.includeDefaults
            ? yieldPerAcreDefaults
            : yieldPerAcre
        }

        state.hoverData = {
          voxelId,
          acreage,
          farmName,
          fieldName,
          agt,
          cropId,
          elevation: elevationMeters,
          seedVariety,
          fertProduct,
          yieldPerAcre: yieldHover,
          plantingSeedsPerAcre: plantingHover,
          fertAmountPerAcre: fertHover,
          fertAmountPerAcreNew: state.includeDefaults
            ? fertAmountPerAcreDefaultsNew
            : fertAmountPerAcreNew,
          profit: roiProfit,
          voxelAcres,
          x,
          y,
        }
      } else {
        var {
          fieldId,
          voxelId,
          agts,
          cropId,
          elevationMeters,
          yieldPerAcre,
          yieldPerAcreDefaults,
          plantingSeedsPerAcre,
          plantingSeedsPerAcreDefaults,
          fertAmountPerAcre,
          fertAmountPerAcreDefaults,
          roiProfit,
          seedVariety,
          fertProduct,
          voxelAcres,
          fertAmountPerAcreNew,
          fertAmountPerAcreDefaultsNew,
          plantingSeedsPerAcreNew,
          plantingSeedsPerAcreDefaultsNew,
          yieldPerAcreNew,
          yieldPerAcreDefaultsNew,
        } = properties

        if (!fieldId) {
          if (!fieldId) {
            state.hoverData = null
            return
          }
        }

        const fieldData = state.fieldData[fieldId] || {}
        const { acreage, farmName, fieldName } = fieldData

        if (agts != undefined) {
          agt = JSON.parse(agts)["27"]
        }

        let plantingHover, yieldHover
        const plantingNew =
          plantingSeedsPerAcreNew !== null ||
          plantingSeedsPerAcreDefaultsNew !== null
        const yieldNew =
          yieldPerAcreNew !== null || yieldPerAcreDefaultsNew !== null
        let fertHover = state.includeDefaults
          ? fertAmountPerAcreDefaults
          : fertAmountPerAcre

        if (plantingNew) {
          plantingHover = state.includeDefaults
            ? plantingSeedsPerAcreDefaultsNew
            : plantingSeedsPerAcreNew
        } else {
          plantingHover = state.includeDefaults
            ? plantingSeedsPerAcreDefaults
            : plantingSeedsPerAcre
        }
        if (yieldNew) {
          yieldHover = state.includeDefaults
            ? yieldPerAcreDefaultsNew
            : yieldPerAcreNew
        } else {
          yieldHover = state.includeDefaults
            ? yieldPerAcreDefaults
            : yieldPerAcre
        }

        yieldHover =
          yieldHover != undefined ? JSON.parse(yieldHover) : yieldHover
        plantingHover =
          plantingHover != undefined ? JSON.parse(plantingHover) : plantingHover
        fertHover = fertHover != undefined ? JSON.parse(fertHover) : fertHover

        let thisfertAmountPerAcreNew = state.includeDefaults
          ? fertAmountPerAcreDefaultsNew
          : fertAmountPerAcreNew
        thisfertAmountPerAcreNew =
          thisfertAmountPerAcreNew != undefined
            ? JSON.parse(thisfertAmountPerAcreNew)
            : thisfertAmountPerAcreNew

        if (
          activeLayerIds.includes(MAP_LAYER.Harvest) &&
          yieldHover != undefined
        ) {
          let allCropIds = yieldHover.map(x => Object.keys(x)[0])
          if (
            state.showHarvestCrop != "All" &&
            allCropIds.includes(state.showHarvestCrop)
          ) {
            cropId = parseInt(state.showHarvestCrop)
          } else {
            cropId = parseInt(allCropIds[0])
          }
        } else if (
          activeLayerIds.includes(MAP_LAYER.Planting) &&
          plantingHover != undefined
        ) {
          let allCropIds = plantingHover.map(x => Object.keys(x)[0])

          if (state.showSeed != "All" && allCropIds.includes(state.showSeed)) {
            cropId = parseInt(state.showSeed)
          } else {
            cropId = parseInt(allCropIds[0])
          }
        }

        state.hoverData = {
          voxelId,
          acreage,
          farmName,
          fieldName,
          agt,
          cropId,
          elevation: elevationMeters,
          seedVariety,
          fertProduct,
          yieldPerAcre: yieldHover,
          plantingSeedsPerAcre: plantingHover,
          fertAmountPerAcre: fertHover,
          fertAmountPerAcreNew: thisfertAmountPerAcreNew,
          profit: roiProfit,
          voxelAcres,
          x,
          y,
        }
      }
    } else {
      state.hoverData = null
    }
  }

  const selectedLayerOnHover = evt => {
    const { object, x, y } = evt
    if (object) {
      const { properties } = object
      const { field } = properties

      const { acreage, farm, crops, name: fieldName } = field
      const { name: farmName } = farm
      state.hoverData = {
        acreage,
        farmName,
        crops,
        fieldName,
        x,
        y,
      }
    } else {
      state.hoverData = null
    }
  }

  const toggleField = fieldId => {
    store.commit(Filter.Mutations.toggleItem, {
      id: fieldId,
      dropdownType: DROPDOWN.Field,
      preventAutozoom: true,
    })
  }

  const onClick = evt => toggleField(evt.object.properties.fieldId)

  const selectedLayerOnClick = evt =>
    toggleField(evt.object.properties.field.id)

  const {
    agtCrops,
    activeLayerIds,
    fieldBoundData,
    opacities,
    elevationScale,
    includeDefaults,
  } = state
  const layers = { ...state.layers }

  if (_.isEmpty(fieldData)) {
    return {}
  }

  /*const constVoxelFeatures = getVoxelFeatures(
    fieldData,
    agtCrops,
    activeFieldIds,
    activeLayerIds,
    includeDefaults,
    state.showFert,
    state.showSeed,
    state.showHarvestCrop
  )*/

  let year = state.mvtYear
  const organizationId = state.organizationId
  const mvtURLs = state.mvtURLs

  if (year == null) {
    year = "None"
  }

  const data_pbf_path = `https://mvt-layer.s3.amazonaws.com/${mvtURLs}/${
    import.meta.env.VITE_NODE_ENV
  }/${organizationId}/${year}/{z}/{x}/{y}.pbf`

  const maxCacheSize = 1
  //const maxCacheByteSize = 250000 //2000000000

  if (mvtURLs) {
    if (mvtURLs != "None") {
      const constVoxelFeatures = getVoxelFeatures(
        fieldData,
        agtCrops,
        activeFieldIds,
        activeLayerIds,
        includeDefaults,
        state.showFert,
        state.showSeed,
        state.showHarvestCrop
      )

      const extentArray = [
        state.extentBounds["minLongitude"],
        state.extentBounds["minLatitude"],
        state.extentBounds["maxLongitude"],
        state.extentBounds["maxLatitude"],
      ]

      //const extentArray = null

      if (recalcLayerData || layers[MAP_LAYER.AGT]) {
        layers[MAP_LAYER.AGT] = new AGTGlobal({
          id: MAP_LAYER.AGT,
          data: data_pbf_path,
          extent: extentArray,
          maxCacheSize: maxCacheSize,
          //maxCacheByteSize: maxCacheByteSize,
          visible: agtCrops.length > 0,
          onClick: onClick,
          onHover: onHover,
          opacity: scaleOpacity(opacities[MAP_LAYER.AGT]),
          activeFieldIds: activeFieldIds,
          updateTriggers: {
            getFillColor: activeFieldIds,
          },
        })
      }

      if (recalcLayerData || layers[MAP_LAYER.Elevation]) {
        layers[MAP_LAYER.Elevation] = new Elevation({
          id: MAP_LAYER.Elevation,
          data: data_pbf_path,
          extent: extentArray,
          maxCacheSize: maxCacheSize,
          //maxCacheByteSize: maxCacheByteSize,

          dataset: constVoxelFeatures[MAP_LAYER.Elevation],
          visible: activeLayerIds.includes(MAP_LAYER.Elevation),
          onClick,
          onHover,
          opacity: scaleOpacity(opacities[MAP_LAYER.Elevation]),
          elevationScale: scaleElevationScale(
            elevationScale[MAP_LAYER.Elevation]
          ),
          activeFieldIds: activeFieldIds,
          updateTriggers: {
            getFillColor: [activeFieldIds],
          },
        })
      }

      if (
        (recalcLayerData || layers[MAP_LAYER.Harvest]) &&
        constVoxelFeatures["anyHarvest"]
      ) {
        layers[MAP_LAYER.Harvest] = new Harvest({
          id: MAP_LAYER.Harvest,
          data: data_pbf_path,
          extent: extentArray,
          maxCacheSize: maxCacheSize,
          //maxCacheByteSize: maxCacheByteSize,

          dataset: constVoxelFeatures[MAP_LAYER.Harvest],
          visible: activeLayerIds.includes(MAP_LAYER.Harvest),
          onClick,
          onHover,
          opacity: scaleOpacity(opacities[MAP_LAYER.Harvest]),
          activeFieldIds: activeFieldIds,
          includeDefaults: includeDefaults,
          targetCropId: state.showHarvestCrop,
          updateTriggers: {
            getFillColor: [
              activeFieldIds,
              includeDefaults,
              state.showHarvestCrop,
            ],
          },
        })
      } else {
        delete layers[MAP_LAYER.Harvest]
      }

      if (
        (recalcLayerData || layers[MAP_LAYER.YieldByElevation]) &&
        constVoxelFeatures["anyHarvest"]
      ) {
        layers[MAP_LAYER.YieldByElevation] = new YieldByElevation({
          id: MAP_LAYER.YieldByElevation,
          data: data_pbf_path,
          extent: extentArray,
          maxCacheSize: maxCacheSize,
          //maxCacheByteSize: maxCacheByteSize,

          dataset: constVoxelFeatures[MAP_LAYER.YieldByElevation],
          onClick,
          onHover,
          visible: activeLayerIds.includes(MAP_LAYER.YieldByElevation),
          opacity: scaleOpacity(opacities[MAP_LAYER.YieldByElevation]),
          elevationScale: scaleElevationScale(
            elevationScale[MAP_LAYER.YieldByElevation]
          ),
          activeFieldIds: activeFieldIds,
          includeDefaults: includeDefaults,
          targetCropId: state.showHarvestCrop,
          updateTriggers: {
            getFillColor: [
              activeFieldIds,
              includeDefaults,
              state.showHarvestCrop,
            ],
          },
        })
      } else {
        delete layers[MAP_LAYER.YieldByElevation]
      }

      if (
        (recalcLayerData || layers[MAP_LAYER.Fertilizer]) &&
        constVoxelFeatures["anyFert"]
      ) {
        layers[MAP_LAYER.Fertilizer] = new Fertilizer({
          id: MAP_LAYER.Fertilizer,
          data: data_pbf_path,
          extent: extentArray,
          maxCacheSize: maxCacheSize,
          //maxCacheByteSize: maxCacheByteSize,

          dataset: constVoxelFeatures[MAP_LAYER.Fertilizer],
          visible: activeLayerIds.includes(MAP_LAYER.Fertilizer),
          onClick,
          onHover,
          opacity: scaleOpacity(opacities[MAP_LAYER.Fertilizer]),
          activeFieldIds: activeFieldIds,
          includeDefaults: includeDefaults,
          targetCropId: state.showFert,
          updateTriggers: {
            getFillColor: [activeFieldIds, includeDefaults, state.showFert],
          },
        })
      } else {
        delete layers[MAP_LAYER.Fertilizer]
      }

      if (
        (recalcLayerData || layers[MAP_LAYER.Planting]) &&
        constVoxelFeatures["anyPlanting"]
      ) {
        layers[MAP_LAYER.Planting] = new Planting({
          id: MAP_LAYER.Planting,
          data: data_pbf_path,
          extent: extentArray,
          maxCacheSize: maxCacheSize,
          //maxCacheByteSize: maxCacheByteSize,

          visible: activeLayerIds.includes(MAP_LAYER.Planting),
          onClick,
          onHover,
          opacity: scaleOpacity(opacities[MAP_LAYER.Planting]),
          activeFieldIds: activeFieldIds,
          includeDefaults: includeDefaults,
          targetCropId: state.showSeed,
          updateTriggers: {
            getFillColor: [activeFieldIds, includeDefaults, state.showSeed],
          },
        })
      } else {
        delete layers[MAP_LAYER.Planting]
      }

      if (recalcLayerData || layers[MAP_LAYER.Profit]) {
        layers[MAP_LAYER.Profit] = new Profit({
          id: MAP_LAYER.Profit,
          data: data_pbf_path,
          extent: extentArray,
          maxCacheSize: maxCacheSize,
          //maxCacheByteSize: maxCacheByteSize,

          dataset: constVoxelFeatures[MAP_LAYER.Profit],
          visible: activeLayerIds.includes(MAP_LAYER.Profit),
          onClick,
          onHover,
          opacity: scaleOpacity(opacities[MAP_LAYER.Profit]),
          elevationScale: scaleElevationScale(elevationScale[MAP_LAYER.Profit]),
          activeFieldIds: activeFieldIds,
          includeDefaults: includeDefaults,
          updateTriggers: {
            getFillColor: [activeFieldIds, includeDefaults],
          },
        })
      }

      if (recalcLayerData || layers[MAP_LAYER.AGTByElevation]) {
        layers[MAP_LAYER.AGTByElevation] = new AGTByElevation({
          id: MAP_LAYER.AGTByElevation,
          data: data_pbf_path,
          extent: extentArray,
          maxCacheSize: maxCacheSize,
          //maxCacheByteSize: maxCacheByteSize,

          dataset: constVoxelFeatures[MAP_LAYER.AGTByElevation],
          visible: activeLayerIds.includes(MAP_LAYER.AGTByElevation),
          onClick,
          onHover,
          opacity: scaleOpacity(opacities[MAP_LAYER.AGTByElevation]),
          elevationScale: scaleElevationScale(
            elevationScale[MAP_LAYER.AGTByElevation]
          ),
          activeFieldIds: activeFieldIds,
          updateTriggers: {
            getFillColor: [activeFieldIds],
          },
        })
      }

      if (recalcLayerData || layers[MAP_LAYER.ProfitByAGT]) {
        layers[MAP_LAYER.ProfitByAGT] = new ProfitByAgt({
          id: MAP_LAYER.ProfitByAGT,
          data: data_pbf_path,
          extent: extentArray,
          maxCacheSize: maxCacheSize,
          //maxCacheByteSize: maxCacheByteSize,

          dataset: constVoxelFeatures[MAP_LAYER.ProfitByAGT],
          visible: activeLayerIds.includes(MAP_LAYER.ProfitByAGT),
          onClick,
          onHover,
          opacity: scaleOpacity(opacities[MAP_LAYER.ProfitByAGT]),
          includeDefaults: includeDefaults,
          activeFieldIds: activeFieldIds,

          updateTriggers: {
            getFillColor: [activeFieldIds, includeDefaults],
          },
        })
      }
    } else {
      if (_.isEmpty(fieldData)) {
        return {}
      }

      const constVoxelFeatures = recalcLayerData
        ? getVoxelFeatures(
            fieldData,
            agtCrops,
            activeFieldIds,
            activeLayerIds,
            includeDefaults,
            state.showFert,
            state.showSeed,
            state.showHarvestCrop
          )
        : null

      const extentArray = [
        state.extentBounds["minLongitude"],
        state.extentBounds["minLatitude"],
        state.extentBounds["maxLongitude"],
        state.extentBounds["maxLatitude"],
      ]

      if (recalcLayerData || layers[MAP_LAYER.AGT]) {
        layers[MAP_LAYER.AGT] = new AGTGlobalJSON({
          id: MAP_LAYER.AGT,
          data: recalcLayerData
            ? constVoxelFeatures[MAP_LAYER.AGT]
            : layers[MAP_LAYER.AGT].props.data,
          extent: extentArray,
          maxCacheSize: maxCacheSize,
          //maxCacheByteSize: maxCacheByteSize,
          visible: agtCrops.length > 0,
          onClick: onClick,
          onHover: onHover,
          opacity: scaleOpacity(opacities[MAP_LAYER.AGT]),
          activeFieldIds: activeFieldIds,
          updateTriggers: {
            getFillColor: activeFieldIds,
          },
        })
      }

      if (recalcLayerData || layers[MAP_LAYER.Elevation]) {
        layers[MAP_LAYER.Elevation] = new ElevationJSON({
          id: MAP_LAYER.Elevation,
          data: recalcLayerData
            ? constVoxelFeatures[MAP_LAYER.Elevation]
            : layers[MAP_LAYER.Elevation].props.data,
          extent: extentArray,
          maxCacheSize: maxCacheSize,
          //maxCacheByteSize: maxCacheByteSize,

          dataset: recalcLayerData
            ? constVoxelFeatures[MAP_LAYER.Elevation]
            : layers[MAP_LAYER.Elevation].props.data,
          visible: activeLayerIds.includes(MAP_LAYER.Elevation),
          onClick,
          onHover,
          opacity: scaleOpacity(opacities[MAP_LAYER.Elevation]),
          elevationScale: scaleElevationScale(
            elevationScale[MAP_LAYER.Elevation]
          ),
          activeFieldIds: activeFieldIds,
          updateTriggers: {
            getFillColor: [activeFieldIds],
          },
        })
      }

      if (recalcLayerData || layers[MAP_LAYER.Harvest]) {
        layers[MAP_LAYER.Harvest] = new HarvestJSON({
          id: MAP_LAYER.Harvest,
          data: recalcLayerData
            ? constVoxelFeatures[MAP_LAYER.Harvest]
            : layers[MAP_LAYER.Harvest].props.data,
          extent: extentArray,
          maxCacheSize: maxCacheSize,
          //maxCacheByteSize: maxCacheByteSize,

          dataset: recalcLayerData
            ? constVoxelFeatures[MAP_LAYER.Harvest]
            : layers[MAP_LAYER.Harvest].props.data,
          visible: activeLayerIds.includes(MAP_LAYER.Harvest),
          onClick,
          onHover,
          opacity: scaleOpacity(opacities[MAP_LAYER.Harvest]),
          activeFieldIds: activeFieldIds,
          includeDefaults: includeDefaults,
          targetCropId: state.showHarvestCrop,
          updateTriggers: {
            getFillColor: [
              activeFieldIds,
              includeDefaults,
              state.showHarvestCrop,
            ],
          },
        })
      } else {
        delete layers[MAP_LAYER.Harvest]
      }

      if (recalcLayerData || layers[MAP_LAYER.YieldByElevation]) {
        layers[MAP_LAYER.YieldByElevation] = new YieldByElevationJSON({
          id: MAP_LAYER.YieldByElevation,
          data: recalcLayerData
            ? constVoxelFeatures[MAP_LAYER.YieldByElevation]
            : layers[MAP_LAYER.YieldByElevation].props.data,
          extent: extentArray,
          maxCacheSize: maxCacheSize,
          //maxCacheByteSize: maxCacheByteSize,

          dataset: recalcLayerData
            ? constVoxelFeatures[MAP_LAYER.YieldByElevation]
            : layers[MAP_LAYER.YieldByElevation].props.data,
          onClick,
          onHover,
          visible: activeLayerIds.includes(MAP_LAYER.YieldByElevation),
          opacity: scaleOpacity(opacities[MAP_LAYER.YieldByElevation]),
          elevationScale: scaleElevationScale(
            elevationScale[MAP_LAYER.YieldByElevation]
          ),
          activeFieldIds: activeFieldIds,
          includeDefaults: includeDefaults,
          targetCropId: state.showHarvestCrop,
          updateTriggers: {
            getFillColor: [
              activeFieldIds,
              includeDefaults,
              state.showHarvestCrop,
            ],
          },
        })
      } else {
        delete layers[MAP_LAYER.YieldByElevation]
      }

      if (recalcLayerData || layers[MAP_LAYER.Fertilizer]) {
        layers[MAP_LAYER.Fertilizer] = new FertilizerJSON({
          id: MAP_LAYER.Fertilizer,
          data: recalcLayerData
            ? constVoxelFeatures[MAP_LAYER.Fertilizer]
            : layers[MAP_LAYER.Fertilizer].props.data,
          extent: extentArray,
          maxCacheSize: maxCacheSize,
          //maxCacheByteSize: maxCacheByteSize,

          dataset: recalcLayerData
            ? constVoxelFeatures[MAP_LAYER.Fertilizer]
            : layers[MAP_LAYER.Fertilizer].props.data,
          visible: activeLayerIds.includes(MAP_LAYER.Fertilizer),
          onClick,
          onHover,
          opacity: scaleOpacity(opacities[MAP_LAYER.Fertilizer]),
          activeFieldIds: activeFieldIds,
          includeDefaults: includeDefaults,
          targetCropId: state.showFert,
          updateTriggers: {
            getFillColor: [activeFieldIds, includeDefaults, state.showFert],
          },
        })
      } else {
        delete layers[MAP_LAYER.Fertilizer]
      }

      if (recalcLayerData || layers[MAP_LAYER.Planting]) {
        layers[MAP_LAYER.Planting] = new PlantingJSON({
          id: MAP_LAYER.Planting,
          data: recalcLayerData
            ? constVoxelFeatures[MAP_LAYER.Planting]
            : layers[MAP_LAYER.Planting].props.data,
          extent: extentArray,
          maxCacheSize: maxCacheSize,
          //maxCacheByteSize: maxCacheByteSize,

          visible: activeLayerIds.includes(MAP_LAYER.Planting),
          onClick,
          onHover,
          opacity: scaleOpacity(opacities[MAP_LAYER.Planting]),
          activeFieldIds: activeFieldIds,
          includeDefaults: includeDefaults,
          targetCropId: state.showSeed,
          updateTriggers: {
            getFillColor: [activeFieldIds, includeDefaults, state.showSeed],
          },
        })
      } else {
        delete layers[MAP_LAYER.Planting]
      }

      if (recalcLayerData || layers[MAP_LAYER.Profit]) {
        layers[MAP_LAYER.Profit] = new ProfitJSON({
          id: MAP_LAYER.Profit,
          data: recalcLayerData
            ? constVoxelFeatures[MAP_LAYER.Profit]
            : layers[MAP_LAYER.Profit].props.data,
          extent: extentArray,
          maxCacheSize: maxCacheSize,
          //maxCacheByteSize: maxCacheByteSize,

          dataset: recalcLayerData
            ? constVoxelFeatures[MAP_LAYER.Profit]
            : layers[MAP_LAYER.Profit].props.data,
          visible: activeLayerIds.includes(MAP_LAYER.Profit),
          onClick,
          onHover,
          opacity: scaleOpacity(opacities[MAP_LAYER.Profit]),
          elevationScale: scaleElevationScale(elevationScale[MAP_LAYER.Profit]),
          activeFieldIds: activeFieldIds,
          includeDefaults: includeDefaults,
          updateTriggers: {
            getFillColor: [activeFieldIds, includeDefaults],
          },
        })
      }

      if (recalcLayerData || layers[MAP_LAYER.AGTByElevation]) {
        layers[MAP_LAYER.AGTByElevation] = new AGTByElevationJSON({
          id: MAP_LAYER.AGTByElevation,
          data: recalcLayerData
            ? constVoxelFeatures[MAP_LAYER.AGTByElevation]
            : layers[MAP_LAYER.AGTByElevation].props.data,
          extent: extentArray,
          maxCacheSize: maxCacheSize,
          //maxCacheByteSize: maxCacheByteSize,

          dataset: recalcLayerData
            ? constVoxelFeatures[MAP_LAYER.AGTByElevation]
            : layers[MAP_LAYER.AGTByElevation].props.data,
          visible: activeLayerIds.includes(MAP_LAYER.AGTByElevation),
          onClick,
          onHover,
          opacity: scaleOpacity(opacities[MAP_LAYER.AGTByElevation]),
          elevationScale: scaleElevationScale(
            elevationScale[MAP_LAYER.AGTByElevation]
          ),
          activeFieldIds: activeFieldIds,
          updateTriggers: {
            getFillColor: [activeFieldIds],
          },
        })
      }

      if (recalcLayerData || layers[MAP_LAYER.ProfitByAGT]) {
        layers[MAP_LAYER.ProfitByAGT] = new ProfitByAgtJSON({
          id: MAP_LAYER.ProfitByAGT,
          data: recalcLayerData
            ? constVoxelFeatures[MAP_LAYER.ProfitByAGT]
            : layers[MAP_LAYER.ProfitByAGT].props.data,
          extent: extentArray,
          maxCacheSize: maxCacheSize,
          //maxCacheByteSize: maxCacheByteSize,

          dataset: recalcLayerData
            ? constVoxelFeatures[MAP_LAYER.ProfitByAGT]
            : layers[MAP_LAYER.ProfitByAGT].props.data,
          visible: activeLayerIds.includes(MAP_LAYER.ProfitByAGT),
          onClick,
          onHover,
          opacity: scaleOpacity(opacities[MAP_LAYER.ProfitByAGT]),
          includeDefaults: includeDefaults,
          activeFieldIds: activeFieldIds,

          updateTriggers: {
            getFillColor: [activeFieldIds, includeDefaults],
          },
        })
      }
    }
  }

  const activeFieldIdsDict = {}
  activeFieldIds.forEach(
    activeFieldId => (activeFieldIdsDict[activeFieldId] = true)
  )
  layers[MAP_LAYER.FieldSelection] = new SelectedLayer({
    id: MAP_LAYER.FieldSelection,
    data: fieldBoundData.features.filter(
      feature => activeFieldIdsDict[feature.properties.field.id]
    ),

    visible: true,
    onClick: selectedLayerOnClick,
    onHover: selectedLayerOnHover,
    opacity: scaleOpacity(opacities[MAP_LAYER.Boundary]),
  })

  layers[MAP_LAYER.Boundary] = new BoundaryLayer({
    id: MAP_LAYER.Boundary,
    data: fieldBoundData.features,
    visible: activeLayerIds.includes(MAP_LAYER.Boundary),
    onClick: selectedLayerOnClick,
    onHover: selectedLayerOnHover,
  })

  const cropIconData = fieldBoundData.features
    .filter(
      feature =>
        activeFieldIdsDict[feature.properties.field.id] &&
        feature.properties.field.crops &&
        feature.properties.field.crops.length > 0
    )
    .map(feature => ({
      coordinates: feature.centroid.coordinates,
      crops: feature.properties.field.crops.filter(
        crop => CROP_ID_TO_ICON_URL[crop]
      ),
    }))

  const cropIcon2Data = cropIconData.filter(row => row.crops.length >= 1)
  layers[MAP_LAYER.Crops] = new CropIconLayer(
    {
      id: MAP_LAYER.Crops,
      data: cropIcon2Data,
      visible: state.showCropIcons,
      updateTriggers: state.showCropIcons,
      opacity: state.cropIconOpacity / 100,
    },
    state.zoom
  )

  layers[MAP_LAYER.Microclimate] = new MicroclimateLayer(
    {
      id: MAP_LAYER.Microclimate,
      data: `https://storage.googleapis.com/vector-tilayers-arva/microclimate_v3/{z}/{x}/{y}.pbf`,
      maxCacheSize: maxCacheSize,
      //maxCacheByteSize: maxCacheByteSize,

      visible: activeLayerIds.includes(MAP_LAYER.Microclimate),
      opacity: scaleOpacity(opacities[MAP_LAYER.Microclimate]),
    },
    state.zoom
  )

  return layers
}

const mutations = {
  [Map.Mutations.resetState](state) {
    Object.assign(state, getDefaultState())
  },

  [Map.Mutations.autoZoom](state, resetView) {
    if (state.deck.autoZoom) state.deck.autoZoom(resetView)
  },

  [Map.Mutations.disableCropIcons](state) {
    if (state.showCropIcons) {
      state.showCropIcons = false
      const activeFieldIds = extractFieldIdsFromStore(this.state)
      state.layers = buildLayers(state, state.fieldData, activeFieldIds, true)
    }
  },

  [Map.Mutations.setActiveLayerIds](state, activeLayerIds) {
    state.activeLayerIds = activeLayerIds
    const activeFieldIds = extractFieldIdsFromStore(this.state)
    state.layers = buildLayers(state, state.fieldData, activeFieldIds, true)
  },

  [Map.Mutations.setDeck](state, deck) {
    state.deck = { ...deck }
  },

  [Map.Mutations.setFieldBounds](
    state,
    { data: fieldBoundData, activeFieldIds }
  ) {
    if (!fieldBoundData.features) return

    state.fieldBoundaries = fieldBoundData.features
    const fieldData = { ...state.fieldData }

    const activeFieldIdsDict = {}
    activeFieldIds.forEach(
      activeFieldId => (activeFieldIdsDict[activeFieldId] = true)
    )

    fieldBoundData.features.forEach(feature => {
      const { properties } = feature
      const { field } = properties
      const { acreage, farm, id: fieldId, name: fieldName } = field
      const { name: farmName } = farm
      fieldData[fieldId] = {
        acreage,
        farmName,
        fieldName,
        voxelData: {},
      }
    })

    state.fieldBoundData = fieldBoundData
    state.fieldData = fieldData
    state.layers = buildLayers(state, fieldData, activeFieldIds)
  },

  [Map.Mutations.setMicroclimates](state) {
    const activeFieldIds = extractFieldIdsFromStore(this.state)
    state.layers = buildLayers(state, state.fieldData, activeFieldIds)
  },

  [Map.Mutations.setOrgAgtCrops](state, orgAgtCrops) {
    state.orgAgtCrops = orgAgtCrops
  },

  [Map.Mutations.setHoverData](state, hoverData) {
    state.hoverData = hoverData
  },

  [Map.Mutations.setRightClickData](state, rightClickData) {
    state.rightClickData = rightClickData
  },

  [Map.Mutations.setShowFert](state, showFert) {
    state.showFert = showFert
    const activeFieldIds = extractFieldIdsFromStore(this.state)
    state.layers = buildLayers(state, state.fieldData, activeFieldIds, true)
  },

  [Map.Mutations.setShowSeed](state, showSeed) {
    state.showSeed = showSeed
    const activeFieldIds = extractFieldIdsFromStore(this.state)
    state.layers = buildLayers(state, state.fieldData, activeFieldIds, true)
  },

  [Map.Mutations.setShowHarvest](state, showHarvestCrop) {
    state.showHarvestCrop = showHarvestCrop
    const activeFieldIds = extractFieldIdsFromStore(this.state)
    state.layers = buildLayers(state, state.fieldData, activeFieldIds, true)
  },

  [Map.Mutations.setVoxelBounds](state, voxelBoundData) {
    // reset state values before pushing

    state.activeFerts = ["All"]
    state.activeFertsDefaults = ["All"]
    state.activeSeeds = ["All"]
    state.activeSeedsDefaults = ["All"]
    state.activeHarvests = ["All"]
    state.activeHarvestsDefaults = ["All"]

    const fieldData = { ...state.fieldData }
    voxelBoundData = voxelBoundData || []
    voxelBoundData.forEach(
      ({
        field_id,
        voxel_id,
        geom,
        agts,
        cropId,
        yieldPerAcre,
        yieldPerAcreDefaults,
        plantingSeedsPerAcre,
        plantingSeedsPerAcreDefaults,
        fertAmountPerAcre,
        fertAmountPerAcreDefaults,
        roiProfit,
        seedVariety,
        fertProduct,
        voxelAcres,
        elevationMeters,
        fertAmountPerAcreNew,
        fertAmountPerAcreDefaultsNew,
        plantingSeedsPerAcreNew,
        plantingSeedsPerAcreDefaultsNew,
        yieldPerAcreNew,
        yieldPerAcreDefaultsNew,
      }) => {
        if (fieldData[field_id]) {
          fieldData[field_id].voxelData[voxel_id] = {
            cropId,
            agts,
            geom,
            yieldPerAcre,
            yieldPerAcreDefaults,
            plantingSeedsPerAcre,
            plantingSeedsPerAcreDefaults,
            fertAmountPerAcre,
            fertAmountPerAcreDefaults,
            roiProfit,
            seedVariety,
            fertProduct,
            voxelAcres,
            elevationMeters,
            fertAmountPerAcreNew,
            fertAmountPerAcreDefaultsNew,
            plantingSeedsPerAcreNew,
            plantingSeedsPerAcreDefaultsNew,
            yieldPerAcreNew,
            yieldPerAcreDefaultsNew,
          }
        }
        // seasonality
        // adds fertilizer values
        if (
          fertAmountPerAcreNew !== undefined &&
          fertAmountPerAcreNew !== null
        ) {
          state.showNewFertOptions = true
          fertAmountPerAcreNew.forEach(obj => {
            const fertKey = Object.keys(obj)[0]
            if (!state.activeFerts.includes(fertKey)) {
              state.activeFerts.push(fertKey)
            }
          })
        }
        if (
          fertAmountPerAcreDefaultsNew !== undefined &&
          fertAmountPerAcreDefaultsNew !== null
        ) {
          state.showNewFertOptions = true
          fertAmountPerAcreDefaultsNew.forEach(obj => {
            const fertKey = Object.keys(obj)[0]
            if (!state.activeFertsDefaults.includes(fertKey)) {
              state.activeFertsDefaults.push(fertKey)
            }
          })
        }
        // adds planting crop values
        if (
          plantingSeedsPerAcreNew !== undefined &&
          plantingSeedsPerAcreNew !== null
        ) {
          state.showNewSeedOptions = true
          plantingSeedsPerAcreNew.forEach(obj => {
            const seedKey = Object.keys(obj)[0]
            if (!state.activeSeeds.includes(seedKey)) {
              state.activeSeeds.push(seedKey)
            }
          })
        }
        if (
          plantingSeedsPerAcreDefaultsNew !== undefined &&
          plantingSeedsPerAcreDefaultsNew !== null
        ) {
          state.showNewSeedOptions = true
          plantingSeedsPerAcreDefaultsNew.forEach(obj => {
            const seedKey = Object.keys(obj)[0]
            if (!state.activeSeedsDefaults.includes(seedKey)) {
              state.activeSeedsDefaults.push(seedKey)
            }
          })
        }
        // adds harvest crop values
        if (yieldPerAcreNew !== undefined && yieldPerAcreNew !== null) {
          state.showNewHarvestOptions = true
          yieldPerAcreNew.forEach(obj => {
            const harvKey = Object.keys(obj)[0]
            if (!state.activeHarvests.includes(harvKey)) {
              state.activeHarvests.push(harvKey)
            }
          })
        }
        if (
          yieldPerAcreDefaultsNew !== undefined &&
          yieldPerAcreDefaultsNew !== null
        ) {
          state.showNewHarvestOptions = true
          yieldPerAcreDefaultsNew.forEach(obj => {
            const harvKey = Object.keys(obj)[0]
            if (!state.activeHarvestsDefaults.includes(harvKey)) {
              state.activeHarvestsDefaults.push(harvKey)
            }
          })
        }
      }
    )

    const activeFieldIds = extractFieldIdsFromStore(this.state)

    state.fieldData = fieldData

    state.layers = buildLayers(state, fieldData, activeFieldIds, true)
  },

  [Map.Mutations.setIsFetching](state, val) {
    state.isFetching = val
  },

  [Map.Mutations.setMVTYear](state, val) {
    state.mvtYear = val
  },

  [Map.Mutations.setMVTURLs](state, val) {
    state.mvtURLs = val
  },

  [Map.Mutations.setOrganizationId](state, val) {
    state.organizationId = val
  },

  [Map.Mutations.togglePitchMode](state) {
    if (!state.pitchMode) state.deck.setFullPitch()
    else state.deck.setOverheadView()
    state.pitchMode = !state.pitchMode
  },

  [Map.Mutations.updateIncludeDefaults](state, value) {
    state.includeDefaults = value

    const activeFieldIds = extractFieldIdsFromStore(this.state)
    state.layers = buildLayers(state, state.fieldData, activeFieldIds, true)
  },

  [Map.Mutations.updateIncludeAnimalCounts](state, value) {
    state.includeAnimalCounts = value
  },

  [Map.Mutations.updateShowMicroclimate](state, value) {
    state.showMicroclimate = value
  },

  [Map.Mutations.updateMicroclimateOpacity](state, value) {
    state.microclimateOpacity = value
  },

  [Map.Mutations.updateAgtCrops](state, cropIds) {
    const agtCrops = cropIds.filter(cropId => !state.agtCrops.includes(cropId))
    state.agtCrops = agtCrops

    const activeFieldIds = extractFieldIdsFromStore(this.state)
    const rebuildLayers = state.agtCrops.length > 0
    state.layers = buildLayers(
      state,
      state.fieldData,
      activeFieldIds,
      rebuildLayers
    )
  },

  [Map.Mutations.updateShowCropIcons](state, value) {
    state.showCropIcons = value

    const activeFieldIds = extractFieldIdsFromStore(this.state)
    state.layers = buildLayers(state, state.fieldData, activeFieldIds)
  },

  [Map.Mutations.updateCropIconOpacity](state, opacity) {
    state.cropIconOpacity = opacity

    const activeFieldIds = extractFieldIdsFromStore(this.state)
    state.layers = buildLayers(state, state.fieldData, activeFieldIds)
  },

  [Map.Mutations.updateOpacity](state, { opacity, layerId }) {
    const opacities = { ...state.opacities }
    opacities[layerId] = opacity
    state.opacities = opacities

    const activeFieldIds = extractFieldIdsFromStore(this.state)
    state.layers = buildLayers(state, state.fieldData, activeFieldIds)
  },

  [Map.Mutations.updateElevationScale](state, { elevationScale, layerId }) {
    const elevationScaleDict = { ...state.elevationScale }
    elevationScaleDict[layerId] = elevationScale
    state.elevationScale = elevationScaleDict

    const activeFieldIds = extractFieldIdsFromStore(this.state)
    state.layers = buildLayers(state, state.fieldData, activeFieldIds)
  },

  [Map.Mutations.updateMap](state) {
    const activeFieldIds = extractFieldIdsFromStore(this.state)
    state.layers = buildLayers(state, state.fieldData, activeFieldIds, true)

    if (state.fieldBoundData && activeFieldIds.length > 0) {
      state.extentBounds = calculateExtentBounds(
        state.fieldBoundData,
        activeFieldIds
      )
    }
  },

  [Map.Mutations.updateSingleFieldExtentBounds](state, { fieldId }) {
    if (fieldId) {
      const activeFieldIds = [fieldId]
      state.singleFieldExtentBounds = calculateExtentBounds(
        state.fieldBoundData,
        activeFieldIds
      )
    } else {
      state.singleFieldExtentBounds = {}
    }
  },

  [Map.Mutations.updateBearingPitch](state, { bearing, pitch }) {
    state.currentBearing = bearing
    state.currentPitch = pitch

    if ((pitch > 0 || bearing > 0) && !state.pitchMode) state.pitchMode = true
    if (pitch === 0 && bearing === 0 && state.pitchMode) state.pitchMode = false
  },

  [Map.Mutations.updateZoom](state, zoom) {
    const diff = zoom - state.zoom
    if (Math.abs(diff) > 5) {
      //this is basically designed so still runs monstly just when map is loaded

      state.zoom = zoom

      const activeFieldIds = extractFieldIdsFromStore(this.state)
      state.layers = buildLayers(state, state.fieldData, activeFieldIds)
    }
  },
}

const actions = {
  [Map.Actions.fetchMapData]({ commit }, resetView = true) {
    commit(Map.Mutations.setIsFetching, true)
    return new Promise((resolve, reject) => {
      try {
        let { year, organization } = this.state.Organization

        if (!this.state.Organization.organization.years.includes(year)) {
          year = null
        }
        commit(Map.Mutations.setMVTYear, year)
        commit(Map.Mutations.setOrganizationId, organization.id)

        if (IS_RANCHFORCE) {
          MapAPI.fetchRanchBoundaries(year).then(response => {
            const activeFieldIds = extractFieldIdsFromStore(this.state)
  
            commit(Map.Mutations.setFieldBounds, {
              data: response.data,
              activeFieldIds,
            })
            //setTimeout(() => commit(Map.Mutations.autoZoom, resetView))
            //commit(Map.Mutations.autoZoom, resetView).then(()=>
            commit(Map.Mutations.updateMap)
            MapAPI.fetchRanchMVTURLs(year, organization.id).then(responseMVT => {
              commit(Map.Mutations.setMVTURLs, responseMVT.data)
              MapAPI.fetchRanchVoxels(year, organization.id).then(response => {
                commit(Map.Mutations.setVoxelBounds, response.data)
                commit(Map.Mutations.autoZoom, resetView)
  
                commit(Map.Mutations.setIsFetching, false)
              })
            })
  
            // we want setVoxelBounds() to happen *after* setFieldBounds()
            // the simplest way to ensure this is to fetch the data serially,
            // rather than in parallel
          })
        } else {
          MapAPI.fetchBoundaries(year).then(response => {
            const activeFieldIds = extractFieldIdsFromStore(this.state)
  
            commit(Map.Mutations.setFieldBounds, {
              data: response.data,
              activeFieldIds,
            })
            //setTimeout(() => commit(Map.Mutations.autoZoom, resetView))
            //commit(Map.Mutations.autoZoom, resetView).then(()=>
            commit(Map.Mutations.updateMap)
            MapAPI.fetchMVTURLs(year, organization.id).then(responseMVT => {
              commit(Map.Mutations.setMVTURLs, responseMVT.data)
              MapAPI.fetchVoxels(year, organization.id).then(response => {
                commit(Map.Mutations.setVoxelBounds, response.data)
                commit(Map.Mutations.autoZoom, resetView)
  
                commit(Map.Mutations.setIsFetching, false)
              })
            })
  
            // we want setVoxelBounds() to happen *after* setFieldBounds()
            // the simplest way to ensure this is to fetch the data serially,
            // rather than in parallel
          })
        }
        commit(Map.Mutations.setIsFetching, false)

        resolve()
      } catch (e) {
        console.log("Inside catch(e): ", e)
        commit(Map.Mutations.setIsFetching, false)
        reject(e)
      }
    })
  },
}

export default {
  state,
  actions,
  getters,
  mutations,
}
