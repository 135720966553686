import API from "./API"

const resource = "/paddocks/"

const updatePaddockName = payload => API.post(`${resource}update_paddock_name/`, payload)

const pastureDelete = pastureId => API.post(`${resource}${pastureId}/paddock_delete/`)


export default {
    updatePaddockName,
    pastureDelete,
}